import * as webVitals from "web-vitals";

const reportWebVitals = (onPerfEntry?: any) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        webVitals.onCLS(onPerfEntry);
        webVitals.onINP(onPerfEntry);
        webVitals.onFCP(onPerfEntry);
        webVitals.onLCP(onPerfEntry);
        webVitals.onTTFB(onPerfEntry);
    };
};

export default reportWebVitals;
