import { Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { Client } from "@microsoft/microsoft-graph-client";
import { FetchClient } from "@odata2ts/http-client-fetch";
import { Tenant } from "../service/JiscCloudAssistModelsModel";
import { JiscCloudAssistModelsService } from "../service/JiscCloudAssistModelsService";

export class JTool {
    readonly tenant?: Tenant;
    readonly client?: Client;
    private reqInit?: HeadersInit;
    jservice?: JiscCloudAssistModelsService<FetchClient>;
    constructor(Tenant?: Tenant, Client?: Client, Bearer?: string, ToasterId?: string) {
        this.tenant = Tenant;
        this.client = Client;
        this.toasterId = ToasterId;
        if (Bearer) {
            this.reqInit = { "Authorization": `Bearer ${Bearer}` };
            this.jservice = new JiscCloudAssistModelsService(new FetchClient({ "headers": this.reqInit }), `https://${Providers.globalProvider.customHosts!.at(0)}/v1.0`);
        }
        //refresh the jservice every 10 mins with a new access token
        setInterval(() => {
            Providers.globalProvider.getAccessTokenForScopes((Providers.globalProvider as Msal2Provider).scopes.slice(-1)[0])
            .then((t) => {
                this.reqInit = { "Authorization": `Bearer ${t}` };
                this.jservice = new JiscCloudAssistModelsService(new FetchClient({ "headers": this.reqInit }), `https://${Providers.globalProvider.customHosts!.at(0)}/v1.0`);
                this.jfetch = (path: string, initReq?: RequestInit) => fetch(path, {...initReq, "headers": this.reqInit });
            });
        }, 300000);
    }
    toasterId?: string;
    jfetch = (path: string, initReq?: RequestInit) => fetch(path, {...initReq, "headers": this.reqInit });
    makePath = (path: string, noV1 = false): string => path.startsWith("http") ? path : (`https://${Providers.globalProvider.customHosts!.at(0)}${noV1 ? "" : "/v1.0"}${path.startsWith("/") ? path : `/${path}`}`);
}