import {
    Button, Caption1, Card, CardFooter, CardHeader, CardPreview, Carousel, CarouselAnnouncerFunction,
    CarouselCard, CarouselNav, CarouselNavButton, CarouselSlider, CarouselViewport, Dialog, DialogSurface,
    Image, Link, makeStyles, Tag, Text, Title2, tokens, Tooltip
} from "@fluentui/react-components";
import {
    AlbumRegular, bundleIcon, CartFilled, CartRegular, ContentViewGalleryLightningRegular,
    HeartPulseRegular, LockClosedKeyFilled, LockClosedKeyRegular, OpenFilled, OpenRegular,
    PersonFeedbackRegular
} from "@fluentui/react-icons";
import { Providers } from "@microsoft/mgt-element";
import { ReactElement, startTransition, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import JToolContext from "../Components/JToolContext";
import PageTitle from "../Components/PageTitle";
import useIsSignedIn from "../Hooks/useIsSignedIn";

const useClasses = makeStyles({
    LoginButton: {
        marginTop: "60px",
        marginBottom: "60px",
        alignSelf: "start"
    },
    ConsentButton: {
        alignSelf: "start"
    },
    HomeCardIndent: {
        paddingInlineStart: "16px"
    },
    HomeCardFooter: {
        justifyContent: "flex-end"
    },
    FirstRunFooter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "auto",
        padding: `${tokens.spacingVerticalS} ${tokens.spacingVerticalXXL} ${tokens.spacingVerticalXXL} ${tokens.spacingVerticalXXL}`,
    },
    FirstRunCardText: {
        display: "flex",
        flexDirection: "column",
        gap: tokens.spacingVerticalXXL
    }
});

export default function HomePage(props: { loading?: boolean }): ReactElement {
    const { isSignedIn } = useIsSignedIn();
    const { tenant, makePath } = useContext(JToolContext);
    const navigator = useNavigate();
    const OpenIcon = bundleIcon(OpenFilled, OpenRegular);
    const Key = bundleIcon(LockClosedKeyFilled, LockClosedKeyRegular);
    const CartIcon = bundleIcon(CartFilled, CartRegular);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [firstRun, setFirstRun] = useState<boolean>(JSON.parse(localStorage.getItem("firstRun") ?? "true"));
    const styles = useClasses();

    const getAnnouncement: CarouselAnnouncerFunction = (
        index: number,
        totalSlides: number
    ) => {
        return `First time user guide step ${index + 1} of ${totalSlides}`;
    };

    const navigate = (url: string) => {
        startTransition(() => { navigator(url) });
    };

    useEffect(() => {
        setActiveIndex(0);
    }, []);

    const FirstRunNext = useCallback(() => {
        if (activeIndex === 4) {
            setFirstRun(false);
            localStorage.setItem("firstRun", "false");
            if (Providers.globalProvider.login) Providers.globalProvider.login();
        } else setActiveIndex(activeIndex + 1);
    }, [activeIndex]);

    if (!isSignedIn && !props.loading) return (<section>
        <div className='stack'>
            <PageTitle title="Jisc Cloud Assist" />
            <Text>
                This service is currently early in the Jisc product lifecycle. <br />
                Access is currently limited to a small number of people.<br />
                If you would like to participate in future testing of this tool, please contact us at <Link href="mailto:innovation@jisc.ac.uk">innovation@jisc.ac.uk</Link>
            </Text>
            <Dialog open={firstRun} onOpenChange={(e, data) => setFirstRun(data.open)}>
                <DialogSurface>
                    <Carousel groupSize={1} announcement={getAnnouncement} activeIndex={activeIndex} motion="fade" onActiveIndexChange={(e, data) => setActiveIndex(data.index)}>
                        <CarouselViewport>
                            <CarouselSlider>
                                <CarouselCard className={styles.FirstRunCardText}>
                                    <Image height={200} width={200} style={{ alignSelf: "center" }} alt="Animated Star Emoji" src="https://media.githubusercontent.com/media/microsoft/fluentui-emoji-animated/refs/heads/main/assets/Glowing%20star/animated/glowing_star_animated.png" />
                                    <Title2 tabIndex={-1}>First time setup</Title2>
                                    <Text>If this is the first time your institution is using this tool you will need to setup some app</Text>
                                    <Text>Click next to begin setup</Text>
                                </CarouselCard>
                                <CarouselCard className={styles.FirstRunCardText}>
                                    <Image height={200} width={200} style={{ alignSelf: "center" }} alt="Animated Star Emoji" src="https://media.githubusercontent.com/media/microsoft/fluentui-emoji-animated/refs/heads/main/assets/Face%20with%20monocle/animated/face_with_monocle_animated.png" />
                                    <Title2 tabIndex={-1}>Granting Admin Consent</Title2>
                                    <Text>You will need Global Administrator or Application Administrator to approve Jisc cloud assist&apos;s in your tenant</Text>
                                    <Text>Click the consent button to create the enterprise app registrations in your tenant, and then go next</Text>
                                    <Button className={styles.ConsentButton} as='button' appearance='primary' onClick={() => window.open(makePath("/Tenants/consent"))} iconPosition="after" icon={<OpenIcon />}>Consent</Button>
                                </CarouselCard>
                                <CarouselCard className={styles.FirstRunCardText}>
                                    <Image height={200} width={200} style={{ alignSelf: "center" }} alt="Animated Star Emoji" src="https://media.githubusercontent.com/media/microsoft/fluentui-emoji-animated/refs/heads/main/assets/Handshake/Default/animated/handshake_animated_default.png" />
                                    <Title2 tabIndex={-1}>Assigning the App to users</Title2>
                                    <Text>As we use Entra Enterprise Registrations to control the level of access to the app, follow the instructions below to configure this</Text>
                                    <Text>Follow these instructions to configure the backend enterprise app
                                        <ol>
                                            <li>Go to <Link href="https://entra.microsoft.com/#view/Microsoft_AAD_IAM/StartboardApplicationsMenuBlade/~/AppAppsPreview" target="_blank">Entra ID &gt; Enterprise app</Link></li>
                                            <li>Search for <Tag>Jisc Cloud Assist Service (Backend)</Tag></li>
                                            <li>Under <Tag>Users and Groups</Tag> edit your assignment to the <Tag>Admin</Tag> role</li>
                                            <li>Add any additional users and set the role to either <Tag>Admin</Tag> or <Tag>Readonly</Tag></li>
                                            <li>Under <Tag>Properties</Tag> set the app registration to require user assignment</li>
                                        </ol>
                                    </Text>
                                </CarouselCard>
                                <CarouselCard className={styles.FirstRunCardText}>
                                    <Image height={200} width={200} style={{ alignSelf: "center" }} alt="Animated Star Emoji" src="https://media.githubusercontent.com/media/microsoft/fluentui-emoji-animated/refs/heads/main/assets/Police%20car/animated/police_car_animated.png" />
                                    <Title2 tabIndex={-1}>Requiring assignment for access</Title2>
                                    <Text>The 2nd of the Enterprise apps is the front end, we recommend setting this to require assignment</Text>
                                    <Text>Follow these instructions to configure the frontend enterprise app after you login
                                        <ol>
                                            <li>Go to <Link href="https://entra.microsoft.com/#view/Microsoft_AAD_IAM/StartboardApplicationsMenuBlade/~/AppAppsPreview" target="_blank">Entra ID &gt; Enterprise app</Link></li>
                                            <li>Search for <Tag>Jisc Cloud Assist Service</Tag></li>
                                            <li>Adjust the app registration to require user assignment</li>
                                        </ol>
                                    </Text>
                                </CarouselCard>
                                <CarouselCard className={styles.FirstRunCardText}>
                                    <Image height={200} width={200} style={{ alignSelf: "center" }} alt="Animated Star Emoji" src="https://media.githubusercontent.com/media/microsoft/fluentui-emoji-animated/refs/heads/main/assets/Party%20popper/animated/party_popper_animated.png" />
                                    <Title2 tabIndex={-1}>Lets go</Title2>
                                    <Text>Congratulations, you are done, one final thing though</Text>
                                    <Text>During login, on the 2nd stage permissions request, tick the box to consent for the tenant</Text>
                                </CarouselCard>
                            </CarouselSlider>
                        </CarouselViewport>
                        <div className={styles.FirstRunFooter}>
                            <Button onClick={() => activeIndex === 0 ? setFirstRun(false) : setActiveIndex(activeIndex - 1)}>
                                {activeIndex <= 0 ? "Not Now" : "Previous"}
                            </Button>
                            <CarouselNav appearance="brand">
                                {(index) => (
                                    <CarouselNavButton
                                        aria-label={`First time setup page ${index} of 5`}
                                    />
                                )}
                            </CarouselNav>
                            <Button appearance="primary" onClick={FirstRunNext}>{activeIndex === 4 ? "Lets go" : "Next"}</Button>
                        </div>
                    </Carousel>
                </DialogSurface>
            </Dialog>
            <div className="stack hoz">
                {!firstRun && <Tooltip content="If this is your first time using Cloud Assist please follow the first time wizard before logging in" relationship="description">
                    <Button className={styles.LoginButton} appearance='primary' icon={<Key />} size="large" iconPosition="after" onClick={() => Providers.globalProvider.login ? Providers.globalProvider.login() : undefined}>Login</Button>
                </Tooltip>}
                {firstRun && <Tooltip content="First time wizard" relationship="description">
                    <Button className={styles.LoginButton} appearance='primary' size="large" iconPosition="after" onClick={() => setFirstRun(true)}>First time wizard</Button>
                </Tooltip>}
            </div>
        </div>
    </section>);

    const makeLink = (url: string) =>
        (tenant?.isPaidUp || tenant?.isPreview || tenant?.isReadonly) && tenant.hasCe ? url : "https://www.jisc.ac.uk/cloud/assist-service";

    const navLink = (url: string) => {
        if ((tenant?.isPaidUp || tenant?.isPreview || tenant?.isReadonly) && tenant.hasCe) navigate(url);
        else window.open("https://www.jisc.ac.uk/cloud/assist", "_blank");
    };

    return (<section>
        <div className='stack'>
            <PageTitle title="Jisc Cloud Assist" subtitle="How can we help you today?" />
            <div className="stack cards">
                <Link href={makeLink("/reports")} appearance='subtle' onClick={(e) => e.preventDefault()}>
                    <Card size='large' orientation="horizontal" onSelectionChange={(e, d) => d.selected && navLink("reports")}>
                        <CardPreview>
                            <AlbumRegular />
                        </CardPreview>
                        <CardHeader header={<Text as="h4">Run Device Reports</Text>}
                            description={<ul className={styles.HomeCardIndent}>
                                <li><Caption1>See which devices are connecting to your tenant</Caption1></li>
                                <li><Caption1>Find out of date devices</Caption1></li>
                                <li><Caption1>Generate Device summary reports</Caption1></li>
                            </ul>} />
                        {!tenant?.hasCe && <Tooltip content="Subscribe to the assist service automaton for this feature" relationship="description">
                            <CardFooter className={styles.HomeCardFooter}><Button onClick={() => navLink("reports")} appearance="primary" icon={<CartIcon />}>Subscribe</Button></CardFooter>
                        </Tooltip>}
                    </Card>
                </Link>
                <Link href={makeLink("/automations")} appearance='subtle' onClick={(e) => e.preventDefault()}>
                    <Card size='large' orientation="horizontal" onSelectionChange={(e, d) => d.selected && navLink("automations")}>
                        <CardPreview>
                            <ContentViewGalleryLightningRegular />
                        </CardPreview>
                        <CardHeader header={<Text as="h4">Configure your Device automations</Text>}
                            description={<Caption1>Automatic updates to your policies and Entra devices, on a schedule set by you</Caption1>} />
                        {!tenant?.hasCe && <Tooltip content="Subscribe to the assist service automaton for this feature" relationship="description">
                            <CardFooter className={styles.HomeCardFooter}><Button onClick={() => navLink("reports")} appearance="primary" icon={<CartIcon />}>Subscribe</Button></CardFooter>
                        </Tooltip>}
                    </Card>
                </Link>
                <Link href="/health" appearance='subtle' onClick={(e) => e.preventDefault()}>
                    <Card size='large' orientation="horizontal" onSelectionChange={(e, d) => d.selected && navigate("health")}>
                        <CardPreview>
                            <HeartPulseRegular />
                        </CardPreview>
                        <CardHeader header={<Text as="h4">Run a 365 Health check</Text>}
                            description={<Caption1>Check your tenant against our recommendations</Caption1>} />
                    </Card>
                </Link>
                <Link href="/feedback" appearance='subtle' onClick={(e) => e.preventDefault()}>
                    <Card size='large' orientation="horizontal" appearance='outline' onSelectionChange={(e, d) => d.selected && navigate("feedback")}>
                        <CardPreview>
                            <PersonFeedbackRegular />
                        </CardPreview>
                        <CardHeader header={<Text weight="semibold">Give feedback</Text>}
                            description={<Caption1>Let us know any issues or thoughts you’ve had while testing this app</Caption1>} />
                    </Card>
                </Link>
            </div>
        </div>
    </section>);
}