import { PresenceBadgeStatus } from "@fluentui/react-components";
import * as MGB from "@microsoft/microsoft-graph-types-beta";

export interface Check {
    category: "Compliance"|"Security"|"General"|string;
    area: string;
    name: string;
    state: PresenceBadgeStatus;
}

export interface SubscribedSku extends MGB.SubscribedSku {
    displayName: string | undefined;
    state?: PresenceBadgeStatus;
    grouplicensed?: boolean;
}

export interface IOrganization extends MGB.Organization {
    verifiedDomains: IVerifiedDomain[];
}

export interface IVerifiedDomain extends MGB.VerifiedDomain {
    dkim?: DNSQueryResponse;
    spf?: DNSQueryResponse;
    dmarc?: DNSQueryResponse;
    issues?: number;
}

export interface DNSQueryResponse {
    state: DNSQueryState;
    value?: string;
}

export enum DNSQueryState { Loading, Error, Ok, Mid, Bad }