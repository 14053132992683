import { Theme, webDarkTheme, webLightTheme } from "@fluentui/react-components";
import { useEffect, useState } from "react";

const useTheme = (): [boolean, Theme] => {
    const [theme, setTheme] = useState<Theme>(window.matchMedia("(prefers-color-scheme: dark)").matches ? webDarkTheme : webLightTheme);
    const [isDark, setIsDark] = useState<boolean>(window.matchMedia("(prefers-color-scheme: dark)").matches);
    useEffect(() => {
        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => {
            if (e.matches) {
                setTheme(webDarkTheme);
                setIsDark(true);
            }
        });
        window.matchMedia("(prefers-color-scheme: light)").addEventListener("change", (e) => {
            if (e.matches) {
                setTheme(webLightTheme);
                setIsDark(false);
            }
        });
    }, []);
    return [isDark, theme];
};

export default useTheme;