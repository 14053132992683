import { useEffect, useRef } from 'react';

const useTitle = (title = "", prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    if (!title.match(/Jisc Cloud Assist/gi))
      document.title = `${title}${title.length > 0 && " - "}Jisc Cloud Assist`;
    else document.title = "Jisc Cloud Assist";
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [prevailOnUnmount]);
};

export default useTitle;