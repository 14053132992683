export enum TenantRole {
    Readonly, Admin
}

export interface IJWT {
    "aud": string;
    "iss": string,
    "iat": number,
    "nbf": number,
    "exp": number,
    "acr": string,
    "aio": string,
    "amr": string[],
    "appid": string,
    "appidacr": string,
    "deviceid": string,
    "family_name": string,
    "given_name": string,
    "ipaddr": string,
    "name": string,
    "oid": string,
    "onprem_sid": string,
    "rh": string,
    "roles": string[],
    "scp": string,
    "sub": string,
    "tid": string,
    "unique_name": string,
    "upn": string,
    "uti": string,
    "ver": string
}